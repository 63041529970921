<template>
  <div>
    <div v-if="!pre_loading" class="pre-loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <!-- Dados do Formulário cabeçalho -->
    <v-form v-else @submit.prevent="onSubmit" ref="createForm">
      <v-row dense class="pt-5">
        <v-col :cols="2">
          <v-autocomplete name="operadora" outlined v-model="selectedRow.operadora_id" return-object :items="operadoras"
            item-value="id" item-text="nome" required :rules="[(e) => e !== undefined || 'Obrigatório']"
            @change="getChildrensOperadoras" readonly disabled label="Operadora" dense>
          </v-autocomplete>
        </v-col>
        <v-col :cols="3">
          <v-autocomplete name="paciente.o" outlined v-model="selectedRow.paciente_o.id" return-object
            :items="pacientes" :loading="loading_pac" item-value="id" item-text="nome" required
            :rules="[(e) => e !== undefined || 'Obrigatório']" readonly disabled label="Paciente" dense>
          </v-autocomplete>
        </v-col>
        <v-col :cols="1">
          <v-text-field v-model="selectedRow.paciente_o.id" name="paciente_id" readonly outlined disabled
            label="ID do Paciente" dense></v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="data_nascimento" readonly outlined label="Data de Nascimento" required disabled
            v-model="selectedRow.data_nascimento" :rules="[(e) => e !== undefined || 'Obrigatório']"
            type="date" dense></v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-autocomplete name="modalidade" outlined v-model="selectedRow.modalidade_o" :items="modalidades"
            item-value="id" item-text="nome" required :rules="requiredField" disabled label="Modalidade" dense>
          </v-autocomplete>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="senha" outlined v-model="selectedRow.senha" clearable disabled label="Senha" dense>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col :cols="2">
          <v-text-field name="data_inicio" outlined v-model="selectedRow.data_inicio" disabled type="date"
            :rules="requiredField" clearable label="Data de início - Orçamento" dense>
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="data_fim" outlined v-model="selectedRow.data_fim" disabled type="date" clearable dense
            :rules="requiredField" label="Data de fim - Orçamento">
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="nr_guia_prestador" outlined disabled v-model="selectedRow.nr_guia_prestador" clearable dense
            label="Nrº guia do prestador">
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="nr_guia_operador" outlined disabled v-model="selectedRow.nr_guia_operador" clearable dense
            label="Nrº guia da operadora">
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="senha_data_inicio" outlined disabled v-model="selectedRow.senha_data_inicio" type="date" dense
            clearable label="Data início - Validade senha">
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="senha_data_fim" outlined disabled v-model="selectedRow.senha_data_validade" type="date" dense
            clearable label="Data fim - Validade senha">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense align="center" class="my-2">
        <v-col cols="4" dense>
          <v-text-field name="protocolo_operadora" outlined label="Protocolo na Operadora" dense
            v-model="selectedRow.protocolo_operadora" type="Number" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="4" dense>
          <v-text-field name="data_envio_faturamento" outlined label="Data Envio Faturamento" dense
            v-model="selectedRow.data_envio_faturamento" type="date" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="4" dense>
          <v-text-field name="data_pag_operadora" outlined label="Data do Pagamento" dense
            v-model="selectedRow.data_pag_operadora" type="date" clearable>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense align="center" class="my-2">
        <v-col cols="3" dense>
          <vuetify-money name="valor_glosado" outlined label="Valor Glosado" v-model="selectedRow.valor_glosado" dense
            clearable :options="options">
          </vuetify-money>
        </v-col>
        <v-col cols="2" dense>
          <v-text-field name="protocolo_glosa_operadora" outlined label="Protocolo Glosa na Operadora" dense
            v-model="selectedRow.protocolo_glosa_operadora" type="Number" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="2" dense>
          <v-text-field name="data_envio_recurso_glosa" outlined label="Data do Recurso Glosa" dense
            v-model="selectedRow.data_envio_recurso_glosa" type="date" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="2" dense>
          <v-text-field name="data_pag_operadora_glosa" outlined label="Data do Pagamento Glosa" dense
            v-model="selectedRow.data_pag_operadora_glosa" type="date" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="3" dense>
          <vuetify-money name="valor_glosado_residual" outlined label="Valor da Perda Glosada ou Não Cobrada" dense
            v-model="selectedRow.valor_glosado_residual" clearable :options="options">
          </vuetify-money>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col>
          <v-text-field label="Observações" outlined dense
            v-model="selectedRow.obeservacao"  clearable>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="justify-end gap-4">
        <template>
          <v-col class="text-end mt-1">
            <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree" @click="refreshPage('orcamento')">
              Atualizar Tela </v-btn>
          </v-col>
          <ConfirmButton :loading="isLoading('put:sessao')" :block="false" class="mt-2 mr-2 " outlined
            :onConfirm="onSubmit" color="success">
            Salvar
          </ConfirmButton>
        </template>
      </v-row>
    </v-form>
    <v-divider class="mt-8"></v-divider>

  </div>
</template>

<script>
import api from "../../../http";
import UtilsFunc from "../../../service/utilsFunc";
import '../../../assets/css/styles.css';

export default {
  props: {
    onsubmit: Function,
    onDeleteSession: Function,
    budgetId: Number,
    updateSession: Function,
  },
  components: {
    ConfirmButton: () => import('../../ConfirmButton.vue'),
  },
  data: () => UtilsFunc.withCRUDUtils({
    operadoras: [],
    pacientes: [],
    selectedFile: null,
    loading_pac: false,
    recursoGlosaOptions: [
      { nome: "SIM", value: true },
      { nome: "Não", value: false },
    ],
    modalidades: [],
    selectedRow: {
      manutencao: 0,
      fechamento: 0,
      data_envio_faturamento: null
    },
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    nrProtocoloRecursoGlosaRules: [
      v => !!v || 'Número do Protocolo Recurso de Glosa é obrigatório',
    ],
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
  }),
  methods: {
    async getBudgetDetails() {
      const LOADING_NAME = 'get:budget-details'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`atendimentos/sessoes/?orcamento=${this.budgetId}`);
        // console.log('Sessoes Aqui --> ',data)
        this.selectedRow = data[0];
        if (this.selectedRow) {
          this.selectedRow.paciente_o = this.safeParse(this.selectedRow.paciente_obj);
          this.selectedRow.operadora_o = this.safeParse(this.selectedRow.operadora_obj);
          this.selectedRow.modalidade_o = this.safeParse(this.selectedRow.modalidade);
          this.selectedRow.tipoatendimento_o = this.safeParse(this.selectedRow.tipoatendimento)?.id || null;
          this.selectedRow.indicacaoacidente_o = this.safeParse(this.selectedRow.indicacaoacidente)?.id || null;
          this.selectedRow.regimeatendimento_o = this.safeParse(this.selectedRow.regimeatendimento)?.id || null;
          this.selectedRow.motivo_suspensao_atendimento_o = this.safeParse(this.selectedRow.motivo_suspensao_atendimento);
          this.selectedRow.hospital_o = this.safeParse(this.selectedRow.hospital);
          this.selectedRow.manutencao = this.safeParse(this.selectedRow.manutencao);
          this.selectedRow.fechamento = this.safeParse(this.selectedRow.fechamento);
          this.selectedRow.tipocarateratendimento_o = this.safeParse(this.selectedRow.tipocarateratendimento)?.id || null;
          this.selectedRow.tiporegimeinternacao_o = this.safeParse(this.selectedRow.tiporegimeinternacao)?.id || null;
          this.selectedRow.tipomotivoencerramento_o = this.safeParse(this.selectedRow.tipomotivoencerramento)?.id || null;
          this.selectedRow.tipointernacao_o = this.safeParse(this.selectedRow.tipointernacao)?.id || null;
          this.selectedRow.tipofaturamento_o = this.safeParse(this.selectedRow.tipofaturamento)?.id || null;

        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do orçamento.', error);
        this.$toast.error('Erro ao buscar detalhes do orçamento. Por favor, tente novamente.');
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },

    safeParse(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Erro na string JSON:', jsonString);
        return null;
      }
    },
    async getOperadoras() {
      const { data } = await api.get("operadoras/operadoras/");
      this.operadoras = data.filter(operadora => operadora.ativo);
    },
    async getChildrensOperadoras() {
      this.getPacientes();
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data.filter(modalidade => modalidade.ativo)
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getPacientes() {
      if (!this.selectedRow.operadora_id) return;
      this.loading_pac = true
      const LOADING_NAME = 'get:pacientes'
      this.setLoading(LOADING_NAME)
      const ope_obj = JSON.parse(this.selectedRow?.operadora_obj)
      try {
        const { data } = await api.get(`pacientes/listar/?operadora=${ope_obj.id}&ativo=1`);
        this.pacientes = data;
      } catch (error) {
        alert("Erro no link ao solicitar equipe");
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.loading_pac = false
      }
    },
    async onSubmit() {
      if (this.$refs.createForm.validate()) {
        const fields = {
          ...this.selectedRow,
          pacote: this.pacote_id,
          valor_glosado: this.selectedRow.valor_glosado ? Number(this.selectedRow.valor_glosado) : null,
          valor_glosado_residual: this.selectedRow.valor_glosado_residual ? Number(this.selectedRow.valor_glosado_residual) : null,
          hospital: this.selectedRow.hospital_o ? parseInt(this.selectedRow.hospital_o.id) : null,
          motivo_suspensao_atendimento: this.selectedRow.motivo_suspensao_atendimento_o ? parseInt(this.selectedRow.motivo_suspensao_atendimento_o.id) : null
        };
        // console.log('Enviando --> ', fields)
        const LOADING_NAME = 'put:sessao'
        this.setLoading(LOADING_NAME)
        await this.onsubmit(fields)
        this.setLoading(LOADING_NAME, true)
      }
    },
    refreshPage(method) {
      switch (method) {
        case 'orcamento':
          this.getBudgetDetails();
          break;
        default:
          this.getBudgetDetails();
          break;
      }
    },
  },
  mounted() {
    (async () => {
      await this.getBudgetDetails()
      this.getOperadoras();
      this.getChildrensOperadoras();
      this.getModalidades();
    })()
  },
  computed: {
    pre_loading() {
      return this.pacientes.length > 0 && this.modalidades.length > 0 && this.operadoras.length > 0
    },
  },
};
</script>

<style scoped>
div.pre-loading {
  padding: 5rem 0;
  text-align: center;
}

.clickable-cursor {
  cursor: pointer;
}

.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>

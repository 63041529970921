<template>
  <v-container fluid>
    <v-row>
      <v-col >
          <div class="title-container">
              <h1 class="h1">Acompanhamento do Faturamento</h1>
              <v-tooltip bottom v-if="contaspendentes > 0">
                  <template v-slot:activator="{ on }">
                      <span v-on="on" class="badge"> {{ contaspendentes }} </span>
                  </template>
                  <span>Existe(m) {{ contaspendentes }} conta(s) sem Protocolo na Operadora</span>
              </v-tooltip>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table :loading="isLoading('get')" :items="filteredData" :search="search" :headers="headersWithDynamicInfo"
         no-data-text="Selecione os campos desejados para a pesquisa e clique em 'PESQUISAR'" item-key="id" class="fixed-header-table" fixed-header  height="600px" :items-per-page="-1" >
          <!-- Pesquisa, Relatórios e Filtros -->
          <template v-slot:top>
            <!-- Relatórios -->
                  <div class="align-self-top py-1 text-end" left bottom>
                    <v-btn color="primary" @click="alterarProtocolo" :disabled="multipleSelections.length === 0">
                      Editar Selecionados
                    </v-btn>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                          <v-icon color="green" size="28">mdi-file-excel</v-icon>
                        </v-btn>
                      </template>
                      <span>Exportar para Excel</span>
                    </v-tooltip>
                    <v-menu eager offset-x left offset-y bottom v-model="menu" content-class="menu-content">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                          mdi-printer
                        </v-icon>
                      </template>
                      <v-container class="container">
                        <template v-if="selectedBudgets.length <= 0 && multipleSelections.length <= 0">
                          <RelatorioOrcamentoFiltroOrcamento :displayedData="displayedData"  :dataInicioOrcamento="filters.dateStart.value"
                          :dataFimOrcamento="filters.dateEnd.value"></RelatorioOrcamentoFiltroOrcamento>
                          <RelatorioOrcamentoFiltroRealizado :displayedData="displayedData" :dataInicioOrcamento="filters.dateStart.value"
                          :dataFimOrcamento="filters.dateEnd.value"></RelatorioOrcamentoFiltroRealizado>
                        </template>
                      </v-container>
                    </v-menu>
                  </div>
            <!-- Itens do campo de Pesquisa -->
            <div>
              <v-card dense class="mb-6"> 
                <h4 class="ml-4 mt-4" color></h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Início Orçamento" v-model="filters.dateStart.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Fim Orçamento" v-model="filters.dateEnd.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Início Envio Fatura" v-model="filters.dateStartFatura.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Fim Envio Fatura" v-model="filters.dateEndFatura.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Operadoras" :items="operadoraOptions" v-model="filters.operadora.value"
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Modalidade" :items="modalidadeOptions" v-model="filters.modalidade.value" 
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn color="gree" class="mr-1" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon>Atualizar</v-btn>
                    <v-btn color="success" elevation="0" @click="applyFilters"> Pesquisar </v-btn>
                  </v-col>
                </v-row>   
              </v-card>
                <!-- Campo de Pesquisa -->
                <v-row dense>
                  <v-col cols="6" >
                    <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" />
                  </v-col>
                  <v-col cols="6" class="text-end d-flex align-center justify-end">
                    <div class="d-flex align-center space-between">
                      <span v-if="valorTotalFiltrado" class="faturado-text padded-text">Faturado: {{ valorTotalFiltrado | formatReal }}</span>
                      <span v-if="valorTotalRecebido" class="faturado-text padded-text">Recebido: {{ valorTotalRecebido | formatReal }}</span>
                      <span v-if="valorTotalGlosado" class="glosado-text padded-text">Glosado: {{ valorTotalGlosado | formatReal }}</span>
                      <span v-if="percentualGlosadoReal >= 0" class="percentual-text padded-text">Percentual: {{ percentualGlosadoReal.toFixed(2) }}%</span>
                    </div>
                  </v-col>
                </v-row>
            </div>
          </template>
          <!-- <template v-slot:[`item.equipe`]="{ item }">
            <v-chip class="ma-1" v-for="i in item.equipe" :key="i.id">
              {{ i.first_name }} - {{ i.paciente_id }}
            </v-chip>
          </template> -->
          <template v-slot:[`item.data_inicio`]="{ item }">
            <span v-if="item.data_inicio">
              {{ item.data_inicio | formatDatabr }} até {{ item.data_fim | formatDatabr }}
            </span>
          </template>
           <template v-slot:[`item.data_envio_faturamento`]="{ item }">
            <span v-if="item.data_envio_faturamento">
              {{ item.data_envio_faturamento | formatDatabr }} 
            </span>
          </template>
           <template v-slot:[`item.data_pag_operadora`]="{ item }">
            <span v-if="item.data_pag_operadora">
              {{ item.data_pag_operadora | formatDatabr }} 
            </span>
          </template>
           <template v-slot:[`item.data_envio_recurso_glosa`]="{ item }">
            <span v-if="item.data_envio_recurso_glosa">
              {{ item.data_envio_recurso_glosa | formatDatabr }} 
            </span>
          </template>
           <template v-slot:[`item.data_pag_operadora_glosa`]="{ item }">
            <span v-if="item.data_pag_operadora_glosa">
              {{ item.data_pag_operadora_glosa | formatDatabr }} 
            </span>
          </template>
          <template v-slot:[`item.fatorcamento`]="{ item }">
            {{ item.fatorcamento | formatReal }}
          </template>
          <template v-slot:[`item.fatrealizado`]="{ item }">
            {{ item.fatrealizado | formatReal }}
          </template>
          <template v-slot:[`item.selectMultiple`]="{ item }">
            <v-checkbox
              v-model="selectedBudgets"
              :value="item.id"
              :disabled="isCheckboxDisabled(item)"
              @change="handleMultipleSelectionsChange"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="item.manutencao === '1' && item.fechamento === '0'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  v-bind="attrs" v-on="on" size="30" color="red">mdi-currency-usd</v-icon>
                </template>
                <span>Conta em Manutenção!!!</span>
              </v-tooltip>
            </template>
            <template v-else-if="item.manutencao === '1' && item.fechamento === '1'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="30" color="green">mdi-currency-usd</v-icon>
                </template>
                <span>Conta Fechada / Faturada!!!</span>
              </v-tooltip>
            </template>
            <template v-else-if="item.ativo === false">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="30" color="red">mdi-eye-outline</v-icon>
                </template>
                <span>Orçamento em Aberto!!!</span>
              </v-tooltip>
            </template>
            <template v-else-if="item.manutencao === '0' && item.fechamento === '0'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="30" color="blue">mdi-eye-outline</v-icon>
                </template>
                <span>Orçamento Aprovado!!!</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.realizarrecursoglosa`]="{ item }">
            <div>
              <v-icon v-if="item.realizarrecursoglosa === true" size="30" color="green">mdi-check-bold</v-icon>
            </div>
          </template>
          <template v-slot:[`item.valor_glosado`]="{ item }">
            <span v-if="item.valor_glosado">
              {{ item.valor_glosado | formatReal }}
            </span>
          </template>
          <template v-slot:[`item.fechamento`]="{ item }">
            <div>
              <v-icon v-if="item.fechamento === '1'" size="30" color="green">mdi-check-bold</v-icon>
            </div>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-icon v-if="item.ativo" color="green">mdi-check-circle-outline</v-icon>
            <v-icon v-else color="red">mdi-circle-outline</v-icon>
          </template>
          <template v-slot:[`item.copiar`]="{ item }">
            <v-checkbox v-model="lista_items" :value="item.id"></v-checkbox>
          </template>
          <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox 
              v-model="multipleSelections"
              :value="item.id"
              :disabled="isCheckboxDisabled(item)"
              @change="handleMultipleSelectionsChange"
              ></v-checkbox>
            </div>
          </template>
          <template  v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                  <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Clique para editar</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :style="{ color: 
                                                              item.senha !== null &&
                                                              item.nr_guia_operador !== null &&
                                                              item.nr_guia_prestador !== null &&
                                                              item.senha_data_inicio !== null &&
                                                              item.senha_data_validade !== null
                                                              ? 'green' : 'red' }">{{ item.id }}</span>
              </template>
              <span v-if="item.senha !== null && 
                          item.nr_guia_operador !== null &&
                          item.nr_guia_prestador !== null &&
                          item.senha_data_inicio !== null &&
                          item.senha_data_validade !== null">Guia Completa</span>
              <span v-else>Guia Incompleta</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Edição da Conta  -->
    <FullscreenDialog v-model="dialog.update">
      <v-card>
        <v-card-title class="sticky-title title-border">
          Conta Nrº {{ selectedRow.id }} | Paciente: {{selectedRow.paciente}} 
          <v-spacer></v-spacer>
          <v-btn icon class="sticky-icon" @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <EditForm :onsubmit="updateSession" :budgetId="selectedRow.id" :IdOrcamento="selectedRow.id"
            :updateSession="getSessions" :is-loading="isLoading.bind(this)" :set-loading="setLoading.bind(this)">
          </EditForm>
        </v-card-text>
      </v-card>
    </FullscreenDialog>

    <!-- Modal para inserir Protocolo na Operadora -->
    <v-row>
        <v-dialog v-model="dialog.agrupado" max-width="500px">
          <v-card >
            <v-card-title class="sticky-title title-border" >
              Informações do Envio do Faturamento
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog.agrupado = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form ref="faturamentoForm" v-model="formValid">
                <v-text-field
                  v-model="data_envio_faturamento"
                  label="Data de Envio do Faturamento"
                  type="date"
                  :rules="requiredField"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="protocolo_operadora"
                  label="Protocolo Operadora"
                  :rules="requiredField"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <ConfirmButton :loading="isLoading('put:sessaoInformacao')" :block="false" class="mt-2 mr-2 " outlined
                :onConfirm="confirmarFaturamento" color="success">
                Salvar
              </ConfirmButton>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import EditForm from './componentes/EditForm.vue';
import FullscreenDialog from "../FullscreenDialog.vue";
import '../../assets/css/styles.css';
import { mapGetters } from "vuex";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Sessoes",
  components: {
    EditForm,
    FullscreenDialog,
    ConfirmButton: () => import('../ConfirmButton.vue'),
    RelatorioOrcamentoFiltroOrcamento: () => import('../relatorios/orcamento/RelatorioOrcamentoFiltroOrcamento.vue'),
    RelatorioOrcamentoFiltroRealizado: () => import('../relatorios/orcamento/RelatorioOrcamentoFiltroRealizado.vue')
  },
  props: {
    id: { type: null }
  },
  data: () => withCRUDUtils({
    dialog: {
      clone: false,
      agrupado: false,
    },
    requiredField: [value => !!value || 'Campo obrigatório'],
    errorMessage: 'Campo obrigatório',
    modalidadeOptions: [],
    operadoraOptions: [],
    manutencaoOptions: [
      { nome: "SIM", value: '0' },
      { nome: "NÃO", value: '1' },
    ],
    fechamentoOptions: [
      { nome: "SIM", value: '1' },
      { nome: "NÃO", value: '0' },
    ],
    operadora_: "",
    search: "",
    paciente_: "",
    data_inicio: '',
    data_fim: '',
    modalidades: [],
    activeFilters: [],
    menu: false,
    faturamento: true, 
    formValid: false,
    data_envio_faturamento: '',
    protocolo_operadora: '',
    filters: {
      dateStart: { value: '', compareType: 'gte', prop: 'data_inicio' },
      dateEnd: { value: '', compareType: 'lte', prop: 'data_fim' },
      dateStartFatura: { value: '', compareType: 'gte', prop: 'data_envio_faturamento' },
      dateEndFatura: { value: '', compareType: 'lte', prop: 'data_envio_faturamento' },
      modalidade: { value: '', compareType: 'equal', prop: 'modalidade_o.id' },
      operadora: { value: '', compareType: 'equal', prop: 'operadora_o.id' },
      manutencao: { value: '', compareType: 'equal', prop: 'manutencao' },
      fechamento: { value: '', compareType: 'equal', prop: 'fechamento' },
    },
    newItem: {},
    showFilters: false,
    pacienteId: 0,
    operadoras: [],
    pacientes: [],
    lista_items: [],
    budgets: [],
    selectedBudgets: [], 
    multipleSelections: [],
    loading: false,
    displayedData: [],
    headers: [
      { text: "Editar Conta", align: "center", sortable: false, value: "edit" },
      { text: "Selecionar Conta", align: "center", sortable: false, value: "select_budget", width: 120 },
      { text: "Nrº Orçamento / Conta", class: "font-weight-black", align: "center", sortable: true, value: "id" },
      { text: "Convênio", align: "center", sortable: true, value: "convenio" },
      { text: "Data Orçamento", align: "center", sortable: true, value: "data_inicio" },
      { text: "Valor do Orçamento", align: "center", sortable: true, value: "fatorcamento" },
      { text: "Valor da Conta ", align: "center", sortable: true, value: "fatrealizado" },
      { text: "Protocolo Operadora", align: "center", sortable: true, value: "protocolo_operadora" },
      { text: "Data do Envio", align: "center", sortable: true, value: "data_envio_faturamento" },
      { text: "Data do Pagamento", align: "center", sortable: true, value: "data_pag_operadora" },
      { text: "Valor Glosa", align: "center", sortable: true, value: "valor_glosado" },
      { text: "Protocolo Glosa Operadora", align: "center", sortable: true, value: "protocolo_glosa_operadora" },
      { text: "Data Recurso Glosa", align: "center", sortable: true, value: "data_envio_recurso_glosa" },
      { text: "Data Pagamento Glosa", align: "center", sortable: true, value: "data_pag_operadora_glosa" },
      { text: "Valor Perdido Glosa / Erro", align: "center", sortable: true, value: "valor_glosado_residual" },
      { text: "Observação", align: "center", sortable: true, value: "obeservacao" },
    ],
    selectedOrcamentoId: null,
    idsEvolucoes: [],
    selectedOrcamentoDtInicio: null,
    selectedOrcamentoDtFim: null,
    filteredDatas:[],
    isLoadingEvolucoes: false,
    contaspendentes: 0,
  }),
  methods: {
    async get_listas(url, lista) {
      try {
        const response = await api.get(url);
        if (response.status === 200) {
          if (response.data instanceof Array && response.data.length) {
            // Ordena o array com base no campo 'nome'
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });

            // Filtra o array para manter apenas os elementos com 'ativo' igual a true
            const filteredData = response.data.filter(item => item.ativo === true);
            
            // Atualiza a lista com os dados filtrados
            this[lista] = filteredData;
          }
        }
      } catch (error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    async getOperadoras() {
      try {
        const { data } = await api.get('operadoras/operadoras/');
        this.operadoras = data
          .filter(operadora => operadora && operadora.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data
          .filter(modalidade => modalidade.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getPacientes() {
      try {
        const { data } = await api.get(`pacientes/listar/?operadora=${this.newItem.operadora.id}&ativo=1&empresa=${this.companyData.id}`);
        this.pacientes = data.sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro no link ao solicitar equipe");
      }
    },
    async getChildrensOperadoras() {
      this.getPacientes();
    },
    updateDisplayedData() {
      const filteredByActiveFilters = this.tableData.filter(UtilsFunc.createFilter(this.activeFilters));
      if (this.search) {
        this.displayedData = filteredByActiveFilters.filter(item => {
          // Concatena valores das propriedades em uma string
          const itemString = [
            item.id,
            item.convenio,
            item.paciente,
            item.modalidade,
            item.senha,
            item.nr_guia_operador,
            item.nr_guia_prestador,
            item.paciente_id,
            item.protocolo_operadora, 
            // Adicione outras propriedades que deseja pesquisar
          ].join(' ').toLowerCase();

          // Retorna verdadeiro se a string concatenada incluir o texto da pesquisa
          return itemString.includes(this.search.toLowerCase());
        });
      } else {
        this.displayedData = filteredByActiveFilters;
      }

      // Processamento adicional para "Valor do Orçamento" e "Valor da Conta"
      this.displayedData = this.displayedData.map(item => {
        return {
          ...item,
          fatorcamentoFormatted: item.fatorcamento, // formata o "Valor do Orçamento"
          fatrealizadoFormatted: item.fatrealizado  // formata o "Valor da Conta"
        };
      });
    },
    async getSessions() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get("atendimentos/orcamentos/?empresa=1")
        // console.log(data)
        this.tableData = data
          .filter(e => e.fechamento === '1')
          .map(e => {
            try {
              e['paciente_o'] = JSON.parse(e.paciente_obj);
              e['operadora_o'] = JSON.parse(e.operadora_obj);
              e['modalidade_o'] = JSON.parse(e.modalidade);
            } catch (error) {
              console.error('Erro na string JSON em e.paciente_obj:', e.paciente_obj);
              console.error('Erro na string JSON em e.operadora_obj:', e.operadora_obj);
              console.error('Erro na string JSON em e.modalidade:', e.modalidade);
              console.error(error);
            }
            return e;
            
          })
          .sort((a, b) => {
                const dateA = new Date(a.data_inicio);
                const dateB = new Date(b.data_inicio);
                return dateB - dateA;
          });
        this.displayedData = this.filteredData;
        this.contaspendentes = this.displayedData.filter(item => item.protocolo_operadora === null).length;
        this.updateDisplayedData();
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe, Aconteceu algum erro... ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateSession(fields) {
      const LOADING_NAME = 'put:sessao'
      this.setLoading(LOADING_NAME)
      try {
        // console.log(fields)
        await api.put(`atendimentos/sessoes_put/${fields.id}/`, fields)
        this.$toast.success('Alteração Realizada com Sucesso!')
        this.dialog.update = false
        await this.getSessions()
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe, Aconteceu algum erro... ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    alterarProtocolo() {
    if (this.multipleSelections.length > 0) {
      const sessionData = this.tableData.filter(item => this.multipleSelections.includes(item.id));
      const today = new Date().toISOString().split('T')[0];
      this.data_envio_faturamento = sessionData[0].data_envio_faturamento || today;
      this.protocolo_operadora = sessionData[0].protocolo_operadora || '';
      this.dialog.agrupado = true;
    } else {
      this.$toast.error('Nenhuma seleção feita para faturamento.');
    }
  },
  async confirmarFaturamento() {
    const LOADING_NAME = 'put:sessaoInformacao';
    this.setLoading(LOADING_NAME);
    try {
      for (let sessionId of this.multipleSelections) {
        let sessionData = this.tableData.find(item => item.id === sessionId);

        let hospitalData = JSON.parse(sessionData.hospital || '{}'); // Parse o JSON ou use um objeto vazio

        if (hospitalData && Object.keys(hospitalData).length > 0) {
            sessionData.hospital_o = hospitalData; // Define hospital_o como o objeto completo
            sessionData.hospital = hospitalData.id; // Define hospital como o ID do hospital
        } else {
            delete sessionData.hospital_o; // Remove hospital_o se não houver dados
            delete sessionData.hospital; // Remove hospital se não houver dados
        }

        let motivo_suspensao_atendimentoData = JSON.parse(sessionData.motivo_suspensao_atendimento || '{}'); // Parse o JSON ou use um objeto vazio

        if (motivo_suspensao_atendimentoData && Object.keys(motivo_suspensao_atendimentoData).length > 0) {
            sessionData.motivo_suspensao_atendimento_o = motivo_suspensao_atendimentoData; // Define motivo_suspensao_atendimento_o como o objeto completo
            sessionData.motivo_suspensao_atendimento = motivo_suspensao_atendimentoData.id; // Define motivo_suspensao_atendimento como o ID do motivo_suspensao_atendimento
        } else {
            delete sessionData.motivo_suspensao_atendimento_o; // Remove motivo_suspensao_atendimento_o se não houver dados
            delete sessionData.motivo_suspensao_atendimento; // Remove motivo_suspensao_atendimento se não houver dados
        }

        const fields = {
            ...sessionData,
            pacote: this.pacote_id,
            data_envio_faturamento: this.data_envio_faturamento,
            protocolo_operadora: Number(this.protocolo_operadora),

        };
        await this.updateSession(fields);
      }
      this.dialog.agrupado = false;
      this.$toast.success('Informações de faturamento salvas com sucesso.');
    } catch (error) {
      this.$toast.error(`Erro ao salvar as informações de faturamento. ${error}`);
    } finally {
      this.setLoading(LOADING_NAME, true);
    }
  },

    clearFilters() {
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.activeFilters = []
      this.updateDisplayedData();
      this.dateStartError = false;
      this.dateEndError = false;
      this.search = '';
      this.contaspendentes = 0;
      this.multipleSelections = [];
      this.selectedBudgets = [];
      this.getSessions();
    },
    clearSelecao(){
      this.selectedBudgets = [];
      this.multipleSelections = [];
    },
    applyFilters() {
      // Verifica se todos os filtros estão vazios
      const areAllFiltersEmpty = Object.values(this.filters).every(filter => !filter.value);
      
      if (areAllFiltersEmpty) {
        this.$toast.error('Por favor, escolha pelo menos um critério de pesquisa.');
        return;
      }

      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters).filter(filter => {
        // Inclui o filtro somente se ele tem um valor (não nulo e não vazio)
        if (filter.value === '' || filter.value === null) {
          return false;
        }

        // Tratamento especial para campos como 'modalidade_o.id'
        if (filter.prop === 'modalidade_o.id') {
          return this.tableData.some(data =>
            data.modalidade_o && data.modalidade_o.id.toString() === filter.value.toString()
          );
        }

        return true;
      });

      this.updateDisplayedData();
    },
    handleCheckboxChange(item) {
      this.idsEvolucoes = [];
      this.filteredDatas = [];
      this.multipleSelections = [];
      if (this.selectedBudgets.includes(item.id)) {
        this.selectedBudgets = [];
        this.selectedOrcamentoId = null;
      } else {
        this.selectedBudgets = [item.id];
        this.selectedOrcamentoId = item.id;
        this.selectedOrcamentoDtInicio = item.data_inicio
        this.selectedOrcamentoDtFim = item.data_fim
        this.getEvolucoes()
      }
    },
    validateSameOperadora(selectedIds) {
      // Retorna true se não houver orçamentos selecionados (primeira seleção é sempre válida)
      if (selectedIds.length <= 1) return true;

      // Obtém a operadora do primeiro item selecionado para comparação
      const firstSelectedOperadora = this.tableData.find(item => item.id === selectedIds[0]).operadora_o.id;
      
      // Verifica se todos os orçamentos selecionados têm a mesma operadora
      return selectedIds.every(id => {
        const itemOperadora = this.tableData.find(item => item.id === id).operadora_o.id;
        return itemOperadora === firstSelectedOperadora;
      });
    },
    handleMultipleSelectionsChange(value) {
      if (!this.validateSameOperadora(value)) {
        this.$toast.error('Todos os orçamentos selecionados devem ser da mesma operadora.');
        // Remove o último item selecionado que causou a falha na validação
        this.selectedBudgets.pop();
        return;
      }
  
      this.selectedBudgets = value;
      // Outras operações necessárias...
    },
    isCheckboxDisabled(item) {
      // Se não houver itens selecionados, nenhuma caixa de seleção deve ser desabilitada
      if (this.multipleSelections.length === 0) return false;

      // Obtém a operadora do primeiro item selecionado
      const firstSelectedOperadora = this.tableData.find(data => data.id === this.multipleSelections[0]).operadora_o.id;

      // Desabilita a caixa de seleção se a operadora do item atual for diferente da dos itens já selecionados
      return item.operadora_o.id !== firstSelectedOperadora;
    },
    async getEvolucoes() {
      this.isLoadingEvolucoes = true;
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedOrcamentoId}`)
        this.filteredDatas = data.filter(item => item.assinatura !== null || item.espeical && item.ativo === true);
        this.idsEvolucoes = data
        .filter(item => item.assinatura !== null || item.espeical && item.ativo === true)
        .map(item => item.id);
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpa, aconteceu alguma coisa errada. Erro -> ${error}`)
      } finally {
        this.isLoadingEvolucoes = false;
      }
    },
    async gerarRelatoriosCombinados() {
      if (this.$refs.evolucoesPorEspecialidadeComponent) {
        await this.$refs.evolucoesPorEspecialidadeComponent.generatePDF(true);
      }
      this.$refs.orcamento.getPDF();
      this.$refs.conta.getPDF();
      this.$refs.xml.getXML();
      this.$refs.guiaSadt2.getPDF(false);
    },
    calcularDatasDinamicas() {
      // Obter o primeiro dia do mês anterior
      const today = new Date();
      const ontem = new Date(today);
      ontem.setDate(today.getDate() - 1);
      const primeiroDiaDoMesAtual = new Date(today.getFullYear(), today.getMonth(), 1);
      const primeiroDiaDoMesAnterior = new Date(primeiroDiaDoMesAtual);
      primeiroDiaDoMesAnterior.setMonth(primeiroDiaDoMesAtual.getMonth() - 4);

      // Formatar as datas no formato YYYY-MM-DD
      this.filters.dateStart.value = `${primeiroDiaDoMesAnterior.getFullYear()}-${(primeiroDiaDoMesAnterior.getMonth() + 1).toString().padStart(2, '0')}-${primeiroDiaDoMesAnterior.getDate().toString().padStart(2, '0')}`;
      this.filters.dateEnd.value = `${ontem.getFullYear()}-${(ontem.getMonth() + 1).toString().padStart(2, '0')}-${ontem.getDate().toString().padStart(2, '0')}`;
    },
    async atualizar() {
      this.clearFilters();
      this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
      this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
      this.getOperadoras()
      this.getSessions()
      this.getModalidades()
      this.calcularDatasDinamicas();
      this.applyFilters()
      this.updateDisplayedData();
      if (this.id !== undefined) {
        this.activeFilters = [{ value: Number(this.id), compareType: 'equal', prop: 'id' }]
          ; (async () => {
            await this.getSessions()
            await this.getOperadoras()

            if (this.id) {
              const currentItem = this.tableData.find(r => r.id === Number(this.id))
              this.selectedRow = { ...currentItem }
              this.dialog.update = true
            }
          })()
      } else {
        this.getSessions();
      }
    },
    calcularValorTotal() {
      return this.filteredData.reduce((total, item) => {
        return total + parseFloat(item.fatrealizado || 0);
      }, 0);
    },
    calcularValorTotalRecebido() {
      return this.filteredData.reduce((total, item) => {
        if (item.data_pag_operadora) {
          return total + (parseFloat(item.fatrealizado || 0) - parseFloat(item.valor_glosado || 0));
        }
        return total;
      }, 0);
    },
    calcularValorTotalGlosado() {
      return this.filteredData.reduce((total, item) => {
        return total + parseFloat(item.valor_glosado || 0);
      }, 0);
    },
    percentualGlosa() {
      const valorFaturado = this.valorTotalFiltrado;
      const valorGlosado = this.valorTotalGlosado;
      if (valorFaturado === 0) {
        return 0;
      }
      return (valorGlosado / valorFaturado) * 100;
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Contas');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'contas.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
  },
  mounted() {
    this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
    this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
    this.getOperadoras()
    this.getSessions()
    this.getModalidades()
    this.calcularDatasDinamicas();
    this.applyFilters()
    this.updateDisplayedData();
    if (this.id !== undefined) {
      this.activeFilters = [{ value: Number(this.id), compareType: 'equal', prop: 'id' }]
        ; (async () => {
          await this.getSessions()
          await this.getOperadoras()

          if (this.id) {
            const currentItem = this.tableData.find(r => r.id === Number(this.id))
            this.selectedRow = { ...currentItem }
            this.dialog.update = true
          }
        })()
    } else {
      this.getSessions();
    }
  },
  computed: {
    ...mapGetters(['companyData']),
    selectedRows() {
      return this.filteredData.filter(row => this.lista_items.includes(row.id))
    },
    paciente() {
      return this.pacientes.find(p => p.id === this.pacienteId) || {}
    },
    filteredData() {
    // Verifica se há algum filtro ativo ou se o campo de pesquisa não está vazio
    const isFilterApplied = this.activeFilters.length > 0;
    const isSearchApplied = this.search.trim().length > 0;

    // Retorna os dados filtrados apenas se um filtro ou pesquisa estiver ativo
    if (isFilterApplied || isSearchApplied) {
      return this.displayedData;
    }

    // Retorna um array vazio se nenhum filtro ou pesquisa estiver ativo
    return [];
  },
    isContaFechada() {
      if (this.selectedBudgets.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.selectedBudgets[0]);
        return selectedOrcamento && selectedOrcamento.fechamento === '1';
      }
      return false;
    },
    isContaFechadaXML() {
      if (this.multipleSelections.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.multipleSelections[0]);
        return selectedOrcamento && selectedOrcamento.fechamento === '1';
      }
      // console.log(this.multipleSelections)
      return false;
    },
    isContaRecursoGlosa() {
      if (this.selectedBudgets.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.selectedBudgets[0]);
        return selectedOrcamento && selectedOrcamento.realizarrecursoglosa === true;
      }
      return false;
    },
     guiasIncompletas() {
      return this.filteredData.reduce((count, item) => {
        // Uma guia é considerada incompleta se qualquer um desses campos for null
        if (item.senha === null ||
            item.nr_guia_operador === null ||
            item.nr_guia_prestador === null ||
            item.senha_data_inicio === null ||
            item.senha_data_validade === null) {
          return count + 1;
        }
        return count;
      }, 0);
    },
     headersWithGuiasIncompletas() {
      // Clona o array de headers para não modificar o original armazenado em `data`
      const updatedHeaders = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho onde o texto deve ser atualizado
      const nrOrcamentoIndex = updatedHeaders.findIndex(header => header.value === 'id');

      // Se encontrou o cabeçalho, atualiza seu texto para incluir a contagem de guias incompletas
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        updatedHeaders[nrOrcamentoIndex].text = `"Nrº Orçamento / Conta" (${this.guiasIncompletas} guias incompletas)`;
      }

      return updatedHeaders;
    },
     headersWithDynamicInfo() {
      // Clona o array de headers para não modificar o original
      const headers = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho "Nrº Orçamento / Conta"
      const nrOrcamentoIndex = headers.findIndex(header => header.value === 'id');
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        headers[nrOrcamentoIndex].text = `Nrº Orçamento / Conta (${this.guiasIncompletas} guias incompletas)`;
      }

      const xmlAgrupadoIndex = headers.findIndex(header => header.text.includes('Gerar XML AGRUPADO'));
      if (xmlAgrupadoIndex !== -1) {
        if (this.multipleSelections.length === 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} conta)`;
        } else if (this.multipleSelections.length > 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} contas)`;
        } else {
          headers[xmlAgrupadoIndex].text = 'Gerar XML AGRUPADO'; // Sem seleções
        }
      }
      return headers;
    },
    valorTotalFiltrado() {
      return this.calcularValorTotal();
    },
    valorTotalRecebido() {
      return this.calcularValorTotalRecebido();
    },
    valorTotalGlosado() {
      return this.calcularValorTotalGlosado();
    },
    percentualGlosadoReal() {
      return this.percentualGlosa();
    }
  },
  watch: {
    fsearch() {
      this.updateDisplayedData();
    },
    activeFilters() {
      this.updateDisplayedData();
    },
    search() {
      this.updateDisplayedData();
    },
    filteredData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contaspendentes = newVal.filter(item => item.protocolo_operadora === null).length;
      }
    },
  },

};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
  /* align-items: center; */
}
.sem-evolucoes {
  color: red;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.com-evolucoes {
  color: green;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.title-border {
  border-bottom: 2px solid #E0E0E0 !important; 
  padding-bottom: 10px !important;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.badge {
  background-color: #FAAF19 ;
  color: #0D518F;
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-size: 0.8rem;
  margin-left: 10px; /* Espaço entre o título e a badge */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  flex-shrink: 0; /* Impede que a badge encolha */
}
.alert-message {
  color: red;
  margin-top: 10px;
  width: 300px;
  white-space: pre-wrap; 
}

.faturado-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: green; /* Ajuste a cor conforme necessário */
  margin-top: 4px;
}
.glosado-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: red; /* Ajuste a cor conforme necessário */
  margin-top: 4px;
}
.percentual-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: red;
  margin-top: 4px;
}

.padded-text {
  padding-right: 20px; /* Ajuste o valor conforme necessário */
}
.space-between {
  display: flex;
  gap: 20px; /* Ajuste o valor conforme necessário */
  align-items: center;
}
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}

</style>